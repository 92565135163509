<style scoped lang="scss">
@import './Home.scss';
</style>
<style scoped>
.form_item >>> .ivu-input{
    width:3.35rem;
    height:0.54rem;
    background: #E4E4E4;
    border:none;
    border-radius:0.05rem;
    padding:0.05rem 0 0.05rem 0.38rem;
    box-sizing: border-box;
    font-size:0.15rem;
    color:#999999;
}
.ivu-form-item{
    margin-bottom: 0.3rem;
}
</style>
<!-- 首页 -->
<template>
    <div class="home">    
        <div class="banner_area" id="banner_area">
            <img v-if="isPc" src="./../../assets/Home/1.gif">
            <img v-else src="./../../assets/Home/1.gif">
            <div class="infoes_box" id="informationBox">
                <p>申请免费试用</p>
                <Form
                    ref="form"
                    :model="form"
                    :rules="formValidate">
                    <FormItem class="form_item" prop="name"><Input v-model="form.name" placeholder="请输入您的称呼" /></FormItem>
                    <FormItem class="form_item" prop="telephone"><Input v-model="form.telephone" placeholder="请输入手机号" /></FormItem>
                    <FormItem class="form_item" prop="company"><Input v-model="form.company" placeholder="请输入您的公司名称" /></FormItem>
                    <div class="button" @click="submit()">立即预约</div>
                </Form>
            </div>
        </div>

        <!-- 悬浮咨询按钮 -->
        <div class="fixed_box">
            <img src="./../../assets/Home/consult.png" alt="">
        </div>

        <!-- 公司简介区域 -->
        <div class="company_profile_area">
            <div class="title">公司简介</div>
            <div class="English_title">PLATFORMINTRODUCTION</div>
            <div :class="{bottom_area:true, bottom_area_animate:show1}" ref="position1">
                <div class="left">
                    <div class="content">{{companyProfile}}</div>
                    <div class="icon_group">
                        <div class="per" v-for="(item,index) in group" :key="index">
                            <img src="./../../assets/Home/icon1.png" alt="">
                            <p>{{item}}</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img src="./../../assets/Home/company.png" alt="">
                </div>
            </div>
        </div>

        <!-- 汽车零部件解决方案 -->
        <div class="box3_area">
            <div class="center">
                <div class="left">
                    <video class="video-source"
                        controls="controls" 
                        style="object-fit:fill;margin-top:8px;"
                        x-webkit-airplay="true"
                        x5-video-player-type="h5"
                        x5-video-orientation="h5"
                        x5-video-player-fullscreen="true"
                        preload="auto"
                        autoplay="autoplay"
                        loop="loop"
                        muted="muted">
                        <source src="https://img.zjygdata.com/%E5%AE%98%E7%BD%91/%E5%AE%A3%E4%BC%A0%E7%89%87.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="right">
                    <p :class="{title:true,title_animate:show2}" ref="position2">汽车零部件解决方案</p>
                    <p :class="{English_title:true,English_title_animate:show3}">Automotive parts solutions</p>
                    <div :class="{content:true,content_animate:show4}">{{box3Content}}</div>
                </div>
            </div>
        </div>

        <!-- 团队介绍 -->
        <div class="team_area">
            <div :class="{title:true,title_animate:show5}" ref="position3">团队介绍</div>
            <div :class="{english_title:true,english_title_animate:show6}">ENERGETIC TEAM</div>
            <div :class="{swiper_area:true,swiper_area_animate:show7}" ref="position4">
                <div class="swiper-container" id="teamSwiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper_slide1" v-for="(item,index) in teamList" :key="index">
                            <img :src="item.imgUrl" alt="">
                            <div class="cover_mask"></div>
                            <div class="state1_box">
                                <p class="title1">{{item.title}}</p>
                                <p class="english_title1">{{item.english_title}}</p>
                            </div>
                            <div class="state2_box">
                                <p class="title2">{{item.title}}</p>
                                <div class="content">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Add Pagination -->
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div>

        <!-- 超越软件制造未来 -->
        <div :class="{box5_area:true,box5_area_animate:show8}" ref="position5">
            <p>超 越 软 件 智 造 未 来</p>
            <div class="swiper_box">
                <div class="swiper-container" id="box5Swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in box5List" :key="index">
                            <div class="per_box">
                                <img :src="item.imgUrl" alt="">
                                <div class="font">{{item.font}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 解决方案 -->
        <div class="box6_area" ref="position6">
            <p :class="{big_title:true,big_title_animate:show9}">解决方案</p>
            <p :class="{small_title:true,small_title_animate:show10}">超 越 软 件 智 造 未 来</p>
            <div class="main_module">
                <div class="per_box6_module" v-for="(item,index) in box6List" :key="index" @click="goToLink(index)">
                    <div class="img">
                        <img :src="item.imgUrl" alt="">
                    </div>
                    <div class="bottom">
                        <div class="left_line"></div>
                        <div class="right_title">{{item.font}}</div>
                    </div>
                </div>
                <span></span>
            </div>
            <div class="box6_button" @click="jumpLink()">
                <span>查看更多成功案例</span>
                <div class="img_box">
                    <img src="./../../assets/Home/icon7.png" alt="">
                </div>
            </div>
        </div>

        <!-- 合作伙伴 -->
        <div class="partners_area" ref="position7" id="partners_area">
            <div :class="{partners_title:true,partners_title_animate:show11}">合作伙伴</div>
            <div :class="{eng_title:true,eng_title_animate:show12}">COPRORATE CULTURE</div>
            <div :class="{flex_area:true,flex_area_animate:show13}">
                <div class="per_company">
                    <!-- width:0.89rem;height:0.69rem; -->
                    <div class="img" style="width:0.623rem;height:0.483rem;">
                        <img src="./../../assets/Home/logo1.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">中国联通</p>
                        <p class="font2">China Unicom</p>
                    </div>
                </div>
                <div class="per_company">
                    <div class="img" style="width:1.12rem;height:0.272rem;">
                        <img src="./../../assets/Home/logo2.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">秦权软件</p>
                        <p class="font2">Qin Quan software</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.85rem;height:0.85rem; -->
                    <div class="img" style="width:0.595rem;height:0.595rem;">
                        <img src="./../../assets/Home/logo3.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">深信服科技</p>
                        <p class="font2">Deeply convinced</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.72rem;height:0.71rem -->
                    <div class="img" style="width:0.504rem;height:0.497rem;margin-left:0.2rem;">
                        <img src="./../../assets/Home/logo4.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">飞讯云</p>
                        <p class="font2">Feixun cloud</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:1.03rem;height:0.62rem; -->
                    <div class="img" style="width:0.824rem;height:0.496rem;">
                        <img src="./../../assets/Home/logo5.png" alt="">
                    </div>
                    <div class="logo_font" style="margin-right:0.5rem;">
                        <p class="font1">用友</p>
                        <p class="font2">UFIDA</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.5rem;height:0.73rem; -->
                    <div class="img" style="width:0.4rem;height:0.584rem;">
                        <img src="./../../assets/Home/logo6.png" alt="">
                    </div>
                    <div class="logo_font" style="margin-right:0.2rem;">
                        <p class="font1">金蝶</p>
                        <p class="font2">Kingdee</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.79rem;height:0.78rem; -->
                    <div class="img" style="width:0.553rem;height:0.546rem;">
                        <img src="./../../assets/Home/logo7.png" alt="">
                    </div>
                    <div class="logo_font" style="margin-right:0.17rem;">
                        <p class="font1">中国移动</p>
                        <p class="font2">China Mobile</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.67rem;height:0.69rem; -->
                    <div class="img" style="width:0.536rem;height:0.552rem;margin-left:0.1rem;">
                        <img src="./../../assets/Home/logo8.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">华为</p>
                        <p class="font2">HUAWEI</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.97rem;height:0.67rem; -->
                    <div class="img" style="width:0.679rem;height:0.469rem;">
                        <img src="./../../assets/Home/logo9.png" alt="">
                    </div>
                    <div class="logo_font" style="margin-left:0.2rem;">
                        <p class="font1">航天云网</p>
                        <p class="font2">CASICloud</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.8rem;height:0.74rem; -->
                    <div class="img" style="width:0.56rem;height:0.518rem;margin-left:0.3rem;">
                        <img src="./../../assets/Home/logo10.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">鼎捷软件</p>
                        <p class="font2">Digjin Soft</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:2.5rem;height:0.33rem; -->
                    <div class="img" style="width:2rem;height:0.264rem;">
                        <img src="./../../assets/Home/logo11.png" alt="">
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.95rem;height:0.51rem; -->
                    <div class="img" style="width:0.76rem;height:0.408rem;margin-left:0.22rem;">
                        <img src="./../../assets/Home/logo12.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">泰尔实验室</p>
                        <p class="font2">Tell Laboratory</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.95rem;height:0.51rem; -->
                    <div class="img" style="width:1.2rem;">
                        <img src="./../../assets/Home/logo13.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">畅捷通</p>
                        <p class="font2">Chanjet</p>
                    </div>
                </div>
                <div class="per_company">
                    <!-- width:0.95rem;height:0.51rem; -->
                    <div class="img" style="width:0.86rem;margin-left:0.22rem;">
                        <img src="./../../assets/Home/logo14.png" alt="">
                    </div>
                    <div class="logo_font">
                        <p class="font1">涂鸦智能</p>
                        <p class="font2">Graffiti intelligence</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 联系我们 -->
        <div class="contact_us_area" id="contact_us_area">
            <p class="contact_title">联系我们</p>
            <p class="contact_engtitle">CONTACT US</p>
            <div class="main_content">
                <div class="left">
                    <div class="one">
                        <div class="input_box name">
                            <span>名字：</span>
                            <input type="text">
                        </div>
                        <div class="input_box telephone">
                            <span>电话：</span>
                            <input type="text">
                        </div>
                        <div class="input_box mailbox">
                            <span>邮箱：</span>
                            <input type="text">
                        </div>
                        <div class="input_box company">
                            <span>公司：</span>
                            <input type="text">
                        </div>
                    </div>
                    <div class="two">
                        <span>内容：</span>
                        <textarea name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div class="three">
                        <input type="text" placeholder="请输入验证码">
                        <div class="code"></div>
                        <div class="button">发送</div>
                        <div class="button">在线咨询</div>
                    </div>
                </div>
                <div class="right">
                    <p>地址：浙江省温州市乐清市柳市镇云谷大厦5楼</p>
                    <p>邮编：430000</p>
                    <p>联系电话：13868735925</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { appointmentSMS, validateMobile } from './api'
import Swiper from 'swiper'
import buttomBar from './../../components/buttomBar/buttomBar.vue'

export default{
    name:"Home",
    components:{
        buttomBar,
    },
    data(){
        return {
            form:{},  // 立即预约的表单信息
            // 表单验证规则
            formValidate: {
                name: [{ required: true, message: "不能为空", trigger: "change" }],
                telephone:[{ required: true, message: "不能为空", trigger: "change" },{ validator: validateMobile, trigger: "change" }],
                company:[{ required: true, message: "不能为空", trigger: "change" }],
            },
            screenWidth:window.screenWidth = document.body.clientWidth,
            isPc:true,
            companyProfile:'浙江云谷旭源科技有限公司一家专注于智能制造整体解决方案的科技企业。依托于浙江云谷数字技术研究院，携手华为、涂鸦等伙伴，软硬件通过华为技术方案架构认证及信通院，泰尔实验室验证。以“敏捷、连接、透明、智能”的智能工厂建设理念，为制造业提供智能工厂、数字工厂设计与规划、建设与改造、软硬件系统、工业大数据的应用与服务，团队拥有制造业任职高管 10 余年以上的职业经理人与企业信息化管理支撑专家，并引入全国知名高校及院士人才，设立云谷 - 高校 ( 院士 ) 联合实验基地，与温州理工学院，浙江安防学院进行校企人才联动，致力于成为领先的智能工厂、数字化工厂整体解决方案服务商。2022 年获得浙江省产业数字化转型服务商。',
            group:['敏捷','数据连接','透明','智能'],
            box3Content:'随着汽车市场个性化消费理念的不断增强，全球汽车行业已经开始迈入大规模定制时代，为了满足个性化选配订单、个性化订单快速交付，汽车主机厂一般采用混合柔性JIS排序生产模式，从而要求零部件供应商也采用排序形式进行供货，零部件直接供应到主机厂生产者旁，且对质量追溯要求较高。',
            currentScroll:0,  // 当前鼠标滚动的位置
            teamList:[
                {
                    imgUrl:require('./../../assets/Home/img2.png'),
                    title:'浙江云谷数字研究院',
                    english_title:'Senior development and implementation team',
                    content:'浙江云谷数据中心是温州发展战略性新兴产业的重要工程，它的建设得到了温州市领导的直接关注与大力支持，旨在投资培育下一个信息技术制高点“云计算”的新兴产品基地......',
                },
                {
                    imgUrl:require('./../../assets/Home/img3.png'),
                    title:'资深开发实施团队',
                    english_title:'Senior development and implementation team',
                    content:'浙江云谷数据中心是温州发展战略性新兴产业的重要工程，它的建设得到了温州市领导的直接关注与大力支持，旨在投资培育下一个信息技术制高点“云计算”的新兴产品基地......',
                },
                {
                    imgUrl:require('./../../assets/Home/img4.png'),
                    title:'温州理工学院数据科学 与人工智能学院',
                    english_title:'Senior development and implementation team',
                    content:'浙江云谷数据中心是温州发展战略性新兴产业的重要工程，它的建设得到了温州市领导的直接关注与大力支持，旨在投资培育下一个信息技术制高点“云计算”的新兴产品基地......',
                },
                {
                    imgUrl:require('./../../assets/Home/img2.png'),
                    title:'浙江云谷数字研究院',
                    english_title:'Senior development and implementation team',
                    content:'浙江云谷数据中心是温州发展战略性新兴产业的重要工程，它的建设得到了温州市领导的直接关注与大力支持，旨在投资培育下一个信息技术制高点“云计算”的新兴产品基地......',
                },
                {
                    imgUrl:require('./../../assets/Home/img3.png'),
                    title:'资深开发实施团队',
                    english_title:'Senior development and implementation team',
                    content:'浙江云谷数据中心是温州发展战略性新兴产业的重要工程，它的建设得到了温州市领导的直接关注与大力支持，旨在投资培育下一个信息技术制高点“云计算”的新兴产品基地......',
                },
                {
                    imgUrl:require('./../../assets/Home/img4.png'),
                    title:'温州理工学院数据科学 与人工智能学院',
                    english_title:'Senior development and implementation team',
                    content:'浙江云谷数据中心是温州发展战略性新兴产业的重要工程，它的建设得到了温州市领导的直接关注与大力支持，旨在投资培育下一个信息技术制高点“云计算”的新兴产品基地......',
                },
            ],
            box5List:[
                {
                    imgUrl:require('./../../assets/Home/icon2.png'),
                    font:'工业互联网 低代码开发平台',
                },
                {
                    imgUrl:require('./../../assets/Home/icon3.png'),
                    font:'设备数据采集 分析管理平台',
                },
                {
                    imgUrl:require('./../../assets/Home/icon4.png'),
                    font:'云平台',
                },
                {
                    imgUrl:require('./../../assets/Home/icon5.png'),
                    font:'高校合作',
                },
                {
                    imgUrl:require('./../../assets/Home/icon6.png'),
                    font:'适用性广',
                },
                {
                    imgUrl:require('./../../assets/Home/icon2.png'),
                    font:'工业互联网',
                },
            ],
            box6List:[
                {
                    imgUrl:require('./../../assets/Home/img14.png'),
                    font:'汽车零部件行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/Home/img11.png'),
                    font:'压铸行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/Home/img13.png'),
                    font:'塑胶行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/Home/img12.png'),
                    font:'电子电气配套行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/industrySolutions/img5.png'),
                    font:'通信行业解决方案',
                },
            ],
            // 目前没有用到 partnersList
            partnersList:[
                {
                    imgUrl:require('./../../assets/Home/logo1.png'),
                    name:'中国联通',
                    eng_name:'China Unicom'
                },
                {
                    imgUrl:require('./../../assets/Home/logo2.png'),
                    name:'秦权软件',
                    eng_name:'Qin Quan software'
                },
                {
                    imgUrl:require('./../../assets/Home/logo3.png'),
                    name:'深信服科技',
                    eng_name:'Deeply convinced'
                },
                {
                    imgUrl:require('./../../assets/Home/logo4.png'),
                    name:'飞讯云',
                    eng_name:'Feixun cloud'
                },
                {
                    imgUrl:require('./../../assets/Home/logo5.png'),
                    name:'用友',
                    eng_name:'UFIDA'
                },
                {
                    imgUrl:require('./../../assets/Home/logo6.png'),
                    name:'金蝶',
                    eng_name:'Kingdee'
                },
            ],
            show1:false,   // 动画--公司简介
            show2:false,  // 汽车零部件解决方案 title
            show3:false,  // 汽车零部件解决方案 english_title
            show4:false,  // 汽车零部件解决方案 content
            show5:false,  // 团队介绍
            show6:false,  // 团队介绍
            show7:false,  // 团队介绍
            show8:false,  // 超越软件制造未来
            show9:false, // 解决方案 -- 大标题
            show10:false, // 解决方案 -- 小标题
            show11:false, // 合作伙伴 1
            show12:false, // 合作伙伴 2
            show13:false, // 合作伙伴 3
        }
    },

    watch: {
        // 监听路由，更改头部tab栏的样式及状态
        $route(){
            let name = this.$route.params.name
            if (!(name==' ' || name==null || typeof(name)=='undefined')){
                this.goRegister(this.$route.params.name)
            }
        },
    },

    methods:{
        // 立即预约
        submit(){
            this.$refs.form.validate((valid) => {
                if(valid){
                    appointmentSMS(this.form).then((res)=>{
                        if(res.success){
                            this.$Message.success({
                                content: res.message,
                                duration: 6
                            });
                            this.$refs.form.resetFields();
                            this.form = {}
                        }
                    })
                }
            })
        },

        // 监听鼠标滚，执行动画
        handleScroll(){
            this.currentScroll = window.pageYOffset
            if(this.currentScroll >= this.$refs.position1.offsetTop - 900){
                this.show1 = true
            }
            if(this.currentScroll >= this.$refs.position2.offsetTop - 860){
                this.show2 = true
                this.show3 = true
                this.show4 = true
            }
            if(this.currentScroll >= this.$refs.position3.offsetTop - 900){
                this.show5 = true
                this.show6 = true
            }
            if(this.currentScroll >= this.$refs.position4.offsetTop - 860){
                this.show7 = true
            }
            if(this.currentScroll >= this.$refs.position5.offsetTop - 850){
                this.show8 = true
            }
            if(this.currentScroll >= this.$refs.position6.offsetTop - 790){
                this.show9 = true
                this.show10 = true
            }
            if(this.currentScroll >= this.$refs.position6.offsetTop - 630){
                let box6AllList = document.querySelectorAll('.per_box6_module')
                let box6Button = document.querySelector('.box6_button')
                box6AllList[0].className = 'per_box6_module per_box6_module_animate'
                setTimeout(function(){
                    box6AllList[1].className = 'per_box6_module per_box6_module_animate'
                    setTimeout(function(){
                        box6AllList[2].className = 'per_box6_module per_box6_module_animate'
                        setTimeout(function(){
                            box6AllList[3].className = 'per_box6_module per_box6_module_animate'
                            setTimeout(function(){
                                box6AllList[4].className = 'per_box6_module per_box6_module_animate'
                                // setTimeout(function(){
                                //     box6AllList[5].className = 'per_box6_module per_box6_module_animate'
                                    setTimeout(function(){
                                        box6Button.className = 'box6_button box6_button_animate'
                                    },300)
                                // },300)
                            },300)
                        },300)
                    },300)
                },300)
                
            }
            if(this.currentScroll >= this.$refs.position7.offsetTop - 800){
                this.show11 = true
                this.show12 = true
            }
            if(this.currentScroll >= this.$refs.position7.offsetTop - 650){
                this.show13 = true
            }
            // if(this.currentScroll >= this.$refs.position8.offsetTop - 750){
            //     this.show14 = true
            // }
        },

        swiper1(){
            new Swiper("#teamSwiper", {
                loop:true,
                observer:true,
                observeParents:true,
                slidesPerView: 5,
                spaceBetween: 0, //间距
                freeMode: true, // 撞击反弹
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        },

        swiper2(){
            new Swiper("#box5Swiper", {
                loop:true,
                speed:2500,  //匀速时间
                autoplay:{
                    delay: 0,
                    stopOnLastSlide:false,
                    disableOnInteraction: true,
                },
                observer:true,
                observeParents:true,
                slidesPerView: 5,
                spaceBetween: 0, //间距
                freeMode:true,
            });
        },

        appearanceAnimation(){
            let allSwiperSlide1 = document.querySelectorAll('.swiper_slide1')
            for(let i=0; i<allSwiperSlide1.length; i++){
                allSwiperSlide1[i].addEventListener('mouseenter',function(){
                    for(let j=0; j<allSwiperSlide1.length; j++){
                        allSwiperSlide1[j].children[1].style.display = 'block';
                        allSwiperSlide1[j].className = 'swiper-slide'
                        allSwiperSlide1[j].children[2].className = 'state1_box'
                        allSwiperSlide1[j].children[3].className = 'state2_box'
                    }
                    this.children[1].style.display = 'none';
                    this.className = 'swiper-slide swiper_slide_animate'
                    this.children[2].className = 'state1_box state1_box_animate'
                    this.children[3].className = 'state2_box state2_box_animate'
                })
            }
        },

        jumpLink(){
            this.$router.push({
                name:'industrySolutions',
            })
        },

        // 解决方案跳转链接
        goToLink(index){
            if(index == 0){
                this.$router.push({
                    name:'Solution1'
                })
            }else if(index == 1){
                this.$router.push({
                    name:'Solution2'
                })
            }else if(index == 2){
                this.$router.push({
                    name:'Solution3'
                })
            }else if(index == 3){
                this.$router.push({
                    name:'Solution4'
                })
            }else if(index == 4){
                this.$router.push({
                    name:'Solution5'
                })
            }
        },

        // vue锚点跳转
        goRegister(name) {
            this.$el.querySelector('#'+name).scrollIntoView(
            {
                behavior: "smooth", // 平滑过渡
                block: "start" // 上边框与视窗顶部平齐
            })
        },
    },

    mounted(){
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll)
        this.swiper1()
        this.swiper2()
        this.appearanceAnimation()

        this.screenWidth = window.screenWidth = document.body.clientWidth
        // console.log(this.screenWidth)
        if(this.screenWidth<=770){
            this.isPc = false
        }
        if(this.$route.params.name) {
            this.goRegister(this.$route.params.name)
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll",this.handleScroll)
    },
}
</script>
