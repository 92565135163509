import { getNoAuthRequest } from '@/utils/axios.js'

// 首页 -- 预约短信
export const appointmentSMS = (params) => {
    return getNoAuthRequest(`/applyForProbation/insertInfo`,params)
}


/**
 * 手机号码格式验证
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
 export const validateMobile = (rule, value, callback) => {
    var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!reg.test(value)) {
      callback(new Error('手机号格式错误'));
    } else {
      callback()
    }
  };